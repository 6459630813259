import React from 'react'
import InterpretationService from "../components/ServiceSection/InterpretationService"

import Layout from "../components/layout"

const Interpretation = () => {
    return (
        <Layout>
            <InterpretationService />
        </Layout>
    )
}

export default Interpretation
