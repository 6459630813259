import styled from 'styled-components'


export const ServiceContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 20px 0; 
    background: #ebf5fc;
    margin: auto;
`

export const MainTitle = styled.h1`
    display: flex;
    align-self: center;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background: #ebf5fc;
    margin: 0 20px;
    padding: 0;
    font-family: "Ubuntu", sans-serif;
    font-size: 1.5 rem;
    color: #525354;
    padding-top: 50px;

`

export const Content = styled.div`
    padding: 20px;
    text-align: center;
`

export const Title = styled.h3`
    font-size: 1.3 rem;
    color: #777;
    margin: 30px;
`

export const Text = styled.p`
    font-size: 1 rem;
    font-weight: 300;
    color: #777;
 
`
