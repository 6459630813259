import React from 'react'
import {ServiceContainer, MainTitle, Content, Title, Text} from './ServiceItemElements'
import {graphql, StaticQuery} from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import QuoteFormModal from '../../components/Forms/QuoteFormModal'

import "../HeroSection/HeroSection.css"

const ServicePageImages = () => (
    <StaticQuery
  query={graphql`
    query {
      fileImage: file(relativePath: { eq: "Interpreter_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `}
  render={data => {
    // Set ImageData.
    const imageData = data.fileImage.childImageSharp.fluid
    return (
      <BackgroundImage
        Tag="section"
        className="heroContainer"
        fluid={imageData}
        backgroundColor={`#040e18`}
      >
         
         <div className="overlay">
           <div className="content-area">
           <h1 style={h1Style}>Interpretation Services</h1>
              <QuoteFormModal />
           </div>
          </div>
      </BackgroundImage>
    )
  }}
/>
)

// const sectionStyle = {
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//   };
  
  const h1Style = {
    fontSize: '2.5rem'
  }
  
  
  // const pStyle = {
  //   letterSpacing: '0.04rem',
  //   textAlign: 'flex-start',
  //   color: '#535452',
  //   margin: '80px 50px',
  
  // }




const InterpretationService = () => {
    return (
   
        <ServiceContainer>
        <div>
            <div>
                <ServicePageImages />
            </div>
            <MainTitle>Interpretation Services</MainTitle>
            <Content>
                <div>
                <Title>In-person</Title>
                <Text>
                    Our interpreters provide the best quality of service in legal cases, medical appointments, Human resource services and job interviews.
                </Text>
                </div>
                <div>
                <Title>Over The Phone</Title>
                <Text>We offer a pre-schedule over a phone interpreting which cuts off the cost of travel, reduces time, and helps to overcome language barriers everywhere in the world. We do have professional interpreters who speak rarely spoken languages: Kunama, Tigrinya, Amharic, Oromo, Somali, and Sudanese-Arabic. To learn more, please Call us at 763 777-5699 or email us at agata@sitsinterpreting.com
                </Text>
                </div>
                <div>
                    <Title>Video</Title>
                    <Text>
                    Our interpreters communicate remotely via video chat to reach your customers when holding meetings, for medical appointments, social service appointments, and legal proceedings. 
                    </Text>
                </div>
            </Content>
        </div>
      </ServiceContainer>
     
    )
}

export default InterpretationService
